import { turnOffLenis } from "../scroll.js";

export function plusFalling() {
    const wrapper = document.querySelector('.plus-falling-section_wrap');
    const plusSection = document.querySelector('.plus-falling-section');

    const textBlock = document.querySelector('.counter-section__text-block');
    const textBlockList = document.querySelector('.counter-section__text-block__list');
    const plusElem = document.querySelector('.plus-falling');
    const hwwSection = document.querySelector('.how-we-work-section');

    const tl = gsap.timeline({
        scrollTrigger: {
            scrub: 1,
            pin: true,
            trigger: plusSection,
            start: "bottom bottom",
            endTrigger: wrapper,
            end: "bottom top",
            onLeave: ()=>{
                turnOffLenis(2500);
                plusElem.style.left = '50%';
                plusElem.style.transform = `translate(-50%, -50%) rotate(1440deg)`;
                setTimeout(() => {
                    plusElem.classList.add('active');
                    plusElem.classList.remove('unactive');
                }, 500);
                setTimeout(() => {
                    hwwSection.classList.add('active');
                    textBlock.style.opacity = null;
                }, 1000);
            },
            onEnterBack: ()=>{
                turnOffLenis(2500);
                plusElem.classList.remove('active');
                plusElem.classList.add('unactive');
                textBlock.style.opacity = 1;
                hwwSection.classList.remove('active');
                setTimeout(() => {
                    plusElem.classList.remove('unactive');
                    plusElem.style.left = null;
                }, 1500);
            }
        }
    });
    tl.to(plusElem, {
        onUpdate: ()=>{
            const progress = tl.progress();
            let newSize = 0;
            if(window.innerWidth > 768) {
                newSize = Math.min(Math.max(1.875, (1 - progress) * 6),Math.max(5.75, (1 - progress) * 6));
            } else {
                newSize = Math.min(Math.max(1.875, (1 - progress) * 2.5),Math.max(5.75, (1 - progress) * 2.5));
            }
            
            if(progress > .05) {
                textBlockList.style.transform = `translateY(-${progress * 500}%)`;
                plusElem.style.transform = `translateX(-${progress * 50}%) rotate(${progress * 1440}deg)`;
                plusElem.style.width = newSize + 'rem';
                plusElem.style.height = newSize + 'rem';
                plusElem.style.borderRadius = progress * 50 + '%';
                plusElem.style.top = `${progress * 50}%`;
            } else {
                textBlockList.style.transform = null;
                plusElem.style.transform = null;
                plusElem.style.width = null;
                plusElem.style.height = null;
                plusElem.style.borderRadius = null;
                plusElem.style.top = null;
            }
        }
    });
}