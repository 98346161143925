export function preloads() {
    window.history.scrollRestoration = "manual";
    document.addEventListener('DOMContentLoaded', () => {
        countResources();
    });

    window.addEventListener('load', () => {
        // Убедимся, что прогресс установлен на 100% по завершении загрузки страницы
        if (loadedResources < totalResources) {
            loadedResources = totalResources;
            updatePreloadCounter();
            if(document.querySelector('.preload__counter') && document.querySelector('.preload__counter').querySelector('span').innerText.includes('100')) {
                const preload = document.querySelector('.preload');
                setTimeout(() => {
                    preload.style.top = '110dvh';
                    document.documentElement.style.removeProperty('overflow');
                    window.scrollTo(0, 0);
                }, 1500);
                setTimeout(() => {
                    preload.classList.remove('active');
                    preload.style.top = null;
                }, 3000);
            }
        }
    });
    const preload = document.querySelector('.preload');
    const loadStartBtn = document.querySelector('.__load_start');
    loadStartBtn.addEventListener('click',()=>{
        preload.classList.add('active');
        preload.classList.add('preload_fake');
        window.scrollTo(0, 0);
        setTimeout(() => {
            preload.style.top = '110dvh';
        }, 2000);
        setTimeout(() => {
            preload.classList.remove('active');
            preload.classList.remove('preload_fake');
            preload.style.top = null;
        }, 2500);
    });
    
}

const preloadCounter = document.querySelector('.preload__counter');
const preloadLogo = document.querySelector('.preload__logo');

let totalResources = 0;
let loadedResources = 0;

function resourceLoadHandler() {
    loadedResources++;
    updatePreloadCounter();
}

function updatePreloadCounter() {
    const percentComplete = (loadedResources / totalResources) * 100;
    if(preloadCounter) {
        preloadCounter.querySelector('span').innerText = percentComplete.toFixed(2);
        preloadLogo.style.setProperty('--height', `${100 - percentComplete}%`);
    };
}

function countResources() {
    const elements = document.querySelectorAll('img, script, link[rel="stylesheet"], iframe');
    totalResources = elements.length;

    elements.forEach(element => {
        if (element.complete) {
            loadedResources++;
        } else {
            element.addEventListener('load', resourceLoadHandler);
            element.addEventListener('error', resourceLoadHandler);
        }
    });

    // Обновление начального прогресса
    updatePreloadCounter();
    console.log(`Initial loaded resources: ${loadedResources}`);
    console.log(`Total resources: ${totalResources}`);
}