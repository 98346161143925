import { turnOffLenis } from "../scroll.js";

export function projectsUppear() {
    const wrapper = document.querySelector('.projects-uppear-section_wrap');
    const projectsUppear = document.querySelector('.projects-uppear-section');

    const infoBlock = document.querySelector('.projects-uppear-section__info-block');
    const casesBlock = document.querySelector('.cases-section__cases');
    const image = infoBlock.querySelector('.__project_scroll__image');

    const tl = gsap.timeline({
        scrollTrigger: {
            scrub: 1,
            pin: true,
            trigger: projectsUppear,
            start: "bottom bottom",
            endTrigger: wrapper,
            end: "bottom top",
            onLeave: ()=>{
                turnOffLenis();
            },
            onEnterBack: ()=>{
                turnOffLenis(500);
            }
        }
    });
    tl.to(infoBlock, {
        onUpdate: ()=>{
            const progress = tl.progress();

            image.style.opacity = progress;
            if(window.innerWidth > 768) {
                let bottom = -((1 - progress) * 200) + 50;
                casesBlock.style.bottom = `${progress * 100}dvh`;
                infoBlock.style.bottom = `${bottom}%`;
            } else {
                let top = Math.max((1 - progress) * 200, 50);
                casesBlock.style.top = `-${progress * 100}dvh`;
                infoBlock.style.top = `${top}vh`;
            }
        }
    });
}