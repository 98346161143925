import { turnOffLenis } from "../scroll.js";

export function projectsScroll() {
    const wrapper = document.querySelector('.projects-scroll-section_wrap');
    const projectsScroll = document.querySelector('.projects-scroll-section');

    const infoBlock = document.querySelector('.projects-uppear-section__info-block');
    const images = infoBlock.querySelectorAll('.__project_scroll__image');

    const tl = gsap.timeline({
        scrollTrigger: {
            scrub: 1,
            pin: true,
            trigger: projectsScroll,
            start: "bottom bottom",
            endTrigger: wrapper,
            end: "bottom top",
            onLeave: ()=>{
                turnOffLenis(1500);
                animatedNumbers();
            },
            onEnterBack: ()=>{
                turnOffLenis(1500);
                clearNumbers();
            }
        }
    });
    tl.to(infoBlock,{
        onUpdate: ()=>{
            const progress = tl.progress();

            if(progress > .1) images[0].style.opacity = null;

            const segmentSize = 1 / images.length;
            const activeIndex = Math.floor(progress / segmentSize);

            images.forEach(image=> image.classList.remove('active'));
            if(activeIndex < images.length) images[activeIndex].classList.add('active');
        }
    });
}

function animatedNumbers() {
    const numbers = document.querySelectorAll('.__animated_number');
    const maskedElem = document.querySelector('.projects-uppear-section__info-block__numbers-block__masked-elem');
    const title = document.querySelector('.projects-uppear-section__info-block__title');

    numbers.forEach(number=>{
        const index = parseInt(number.dataset.index);
        number.classList.remove('reverse');
        number.classList.add('active');

        setTimeout(() => {
            if(index == 1) number.style.transform = 'translateY(-200dvh)';
            if(index == 2) {
                number.style.backgroundColor = '#FFFFFF';
                if(window.innerWidth < 768) {
                    number.style.transform = 'translate(-50%, -50%)';
                    number.style.height = 'calc(100vw - 2rem)';
                } else {
                    number.style.transform = 'translateX(-50%)';
                }
            }
            if(index == 3) number.style.transform = 'translateY(-200dvh)';
            
            number.classList.remove('active');
        }, 1000);
    });

    title.style.maxHeight = 0;
    title.classList.add('unactive');
    maskedElem.classList.add('unactive');
}
function clearNumbers() {
    const numbers = document.querySelectorAll('.__animated_number');
    const maskedElem = document.querySelector('.projects-uppear-section__info-block__numbers-block__masked-elem');
    const title = document.querySelector('.projects-uppear-section__info-block__title');

    numbers.forEach(number=>{
        number.classList.add('reverse');

        setTimeout(() => {
            number.style.transform = null;
            number.style.backgroundColor = null;
            number.style.height = null;
            number.classList.remove('reverse');
        }, 1000);
    });

    title.style.maxHeight = null;
    title.classList.remove('unactive');
    setTimeout(() => {
        maskedElem.classList.remove('unactive');
    }, 1000);

}