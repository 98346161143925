import { turnOffLenis } from "../scroll.js";

export function services() {
    const servicesWrappers = document.querySelectorAll('.services-section_wrap');
    const infoTexts = document.querySelectorAll('.services-section__text-block');
    const servicesText = document.querySelector('.services-text');

    if(window.innerWidth < 768) return;

    const pairs = new SectionNText({
        sections: servicesWrappers,
        texts: infoTexts
    });

    pairs.returnPairs().forEach((pair, index)=>{
        const pairBefore = pairs.returnPairs()[index - 1];

        const wrapper = pair[0];
        const text = pair[1];
        
        const servicesSection = wrapper.querySelector('.services-section');
        const infoBlock = servicesSection.querySelector('.services-section__info-block');

        const title = text.querySelector('.services-section__text-block__title');
        const tags = text.querySelector('.services-section__text-block__tags');

        const tl = gsap.timeline({
            scrollTrigger: {
                scrub: 1,
                pin: true,
                trigger: servicesSection,
                start: "75% bottom",
                endTrigger: wrapper,
                end: "bottom bottom",
                onLeave: () => {
                    servicesSection.classList.add('unactive');
                    setTimeout(() => {
                        servicesSection.classList.add('index');
                    }, 500);

                    if(!wrapper.classList.contains('develop')) turnOffLenis();
                },
                onEnterBack: () => {
                    servicesSection.classList.remove('index');
                    servicesSection.classList.remove('unactive');

                    if(!wrapper.classList.contains('develop')) turnOffLenis();
                }
            }
        });
        tl.to(infoBlock,{
            onUpdate:() =>{
                const progress = tl.progress()
                const rect = infoBlock.getBoundingClientRect();

                if(progress < .001) servicesSection.classList.remove('index');

                let width = Math.max(progress * 150, 43.625);
                let widthX2 = Math.max(Math.min(progress * 300, 150), 43.625);
                let bottom;

                if(window.innerWidth > 768) {
                    bottom = Math.min((window.innerHeight - rect.top - rect.height / 2), (window.innerHeight / 2));
                } else {
                    bottom = window.innerHeight - rect.top - rect.height / 2;
                }

                if(width == 43.625) {
                    width = width + 'rem';
                } else {
                    width = width + 'dvw';
                }
                if(widthX2 == 43.625) {
                    widthX2 = widthX2 + 'rem';
                } else {
                    widthX2 = widthX2 + 'dvw';
                }

                if(index == 0) {
                    servicesText.style.opacity = 1 - progress * 2;
                    if(window.innerWidth > 768) {
                        servicesText.style.transform = `translateX(-50%) translateY(-${progress * 100}dvh)`;
                    } else {
                        servicesText.style.transform = `translateY(-${progress * 100}dvh)`;
                    }
                } else if (pairBefore && !wrapper.classList.contains('develop')) {
                    const textBefore = pairBefore[1];
                    textBefore.opacity = 1 - progress * 2;
                    if(window.innerWidth > 768) {
                        textBefore.style.transform = `translateX(-50%) translateY(-${progress * 100}dvh)`;
                    } else {
                        textBefore.style.transform = `translateY(-${progress * 100}dvh)`;
                    }
                } else if(pairBefore && wrapper.classList.contains('develop')) {
                    const textBefore = pairBefore[1];
                    textBefore.opacity = 1 - progress * 2;
                    if(window.innerWidth > 768) {
                        textBefore.style.transform = `translateX(-50%) translateY(-${progress * 200}dvh)`;
                    } else {
                        textBefore.style.transform = `translateY(-${progress * 200}dvh)`;
                    }
                }

                if(!wrapper.classList.contains('develop')) {
                    infoBlock.style.minWidth = width;
                    text.style.bottom = `${bottom}px`;
                    tags.style.scale = Math.max(Math.min(progress * 1.5, 1), .5);
                    title.style.scale = Math.max(Math.min(progress * 1.5, 1), .5);
                    infoBlock.style.transform = `translateY(-${progress * 70}%)`;
                } else {

                    infoBlock.style.transform = `translateY(-${Math.min(progress * 140, 70)}%)`;
                    text.style.bottom = `${bottom}px`;
                    if(progress < .5) {
                        infoBlock.style.minWidth = widthX2;
                        tags.style.scale = Math.max(Math.min(progress * 3, 1), .5);
                        title.style.scale = Math.max(Math.min(progress * 3, 1), .5);
                        servicesSection.classList.remove('unactive');

                        infoBlock.style.opacity = null;
                        text.style.opacity = null;
                    } else {
                        infoBlock.style.minWidth = Math.max(Math.min((1 - progress) * 300, 150), 43.625) + 'dvw';
                        let opacitySet = 1 - progress;
                        servicesSection.classList.remove('unactive');

                        if(opacitySet < .15) opacitySet = 0;

                        infoBlock.style.opacity = opacitySet;
                        text.style.opacity = opacitySet;

                        if(progress > .7) servicesSection.classList.add('unactive');
                    }

                }
            }
        });
    });
}

class SectionNText{
    constructor (options) {
        this.$sections = options.sections;
        this.$texts = options.texts;
    }
    returnPairs() {
        const fullArray = [];
        this.$sections.forEach((section,index)=>{
            const text = this.$texts[index];
            const pairArr = [section, text];
            fullArray.push(pairArr);
        })
        return fullArray;
    }
}