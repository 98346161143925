export function text() {
    const wrapper = document.querySelector('.text-section_wrap');
    const textSection = document.querySelector('.text-section');
    
    const infoBlock = document.querySelector('.text-section__info-block');
    const textTransparent = infoBlock.querySelectorAll('.__text_transparent');
    const benefitsImage = document.querySelector('.__benefits_image');
 
    const tl = gsap.timeline({
        scrollTrigger: {
            scrub: 1,
            pin: true,
            trigger: textSection,
            start: "bottom bottom",
            endTrigger: wrapper,
            end: "bottom bottom",
            onLeave: ()=>{
                textTransparent.forEach(text=>{
                    text.style.setProperty('--width', '0');
                });
            },
            onEnterBack: ()=>{
                textTransparent.forEach(text=>{
                    text.style.setProperty('--width', '0');
                });
            }
        }
    });
    tl.to(infoBlock,{
        onUpdate: ()=>{
            const progress = tl.progress();

            const scale = Math.min(progress * 50, 1);
            infoBlock.style.transform = `translate(-50%, -50%) scale(${scale})`;
            infoBlock.style.opacity = scale;

            const segmentSize = 1 / textTransparent.length;
            const activeIndex = Math.floor(progress / segmentSize);


            if(progress < .001) {
                textTransparent.forEach(text=>{
                    text.style.setProperty('--width', '102%');
                });
            }

            textTransparent.forEach((text, index)=>{
                if(activeIndex < textTransparent.length && index == activeIndex) {
                    let newWidth = (1 - (progress * textTransparent.length - activeIndex)) * 100;
                    if(newWidth > 95) newWidth = 102;
                    if(newWidth < 5) newWidth = 0;
                    text.style.setProperty('--width', `${newWidth}%`);
                }
            });
        }
    });
}