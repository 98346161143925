import { turnOffLenis } from "../scroll.js";

export function letters() {
    const wrapper = document.querySelector('.letters-section_wrap');
    const letters = document.querySelector('.letters-section');

    const imageFixed = document.querySelector('.letters-section__our-team');

    const runningStrokes = document.querySelectorAll('.__running_stroke');
    runningStrokes.forEach(stroke=>{
        const elem = stroke.querySelector('div');
        let position = elem.scrollWidth;

        function animate() {
            position--;
            if (position < -elem.offsetWidth) {
              position = elem.parentElement.offsetWidth;
            }
            elem.style.transform = `translateX(${position}px)`;
            requestAnimationFrame(animate);
        }

        if(window.innerWidth < 768) animate()
    });

    const tl = gsap.timeline({
        scrollTrigger: {
            scrub: 1,
            pin: true,
            trigger: letters,
            start: "bottom bottom",
            endTrigger: wrapper,
            end: "bottom top",
            onLeave: ()=>{
                turnOffLenis();
                imageFixed.classList.add('no-br');
            },
            onEnterBack: ()=>{
                turnOffLenis(500);
                imageFixed.classList.remove('no-br');
            }
        }
    });
    tl.to(imageFixed,{
        onUpdate: ()=>{
            const progress = tl.progress();

            let width = Math.max(0, progress * 110) + 'dvw';
            let height = Math.max(0, progress * 110) + 'dvh';
            
            if(progress < 0.01) {
                imageFixed.classList.remove('active');
                imageFixed.style.maxWidth = null;
                imageFixed.style.maxHeight = null;
            } else {
                imageFixed.classList.add('active');
                imageFixed.style.maxWidth = width;
                imageFixed.style.maxHeight = height;
            }
        }
    });
}