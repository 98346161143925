import { turnOffLenis } from "../scroll.js";

export function google() {
    const wrapper = document.querySelector('.google-section_wrap');
    const google = document.querySelector('.google-section');

    const googleText = document.querySelector('.google-section__text');
    const ourTeamImage = document.querySelector('.letters-section__our-team').querySelector('img');

    const tl = gsap.timeline({
        scrollTrigger: {
            scrub: 1,
            pin: true,
            trigger: google,
            start: "bottom bottom",
            endTrigger: wrapper,
            end: "bottom top",
        }
    });
    tl.to(googleText, {
        onUpdate: ()=>{
            const progress = tl.progress();
            if(progress < .01) {
                googleText.classList.remove('active');
            } else {
                googleText.classList.add('active');
            }

            googleText.style.right = `-${(1 - progress) * 225}dvw`;
            if(window.innerWidth < 768) {
                if(progress < .001) {
                    ourTeamImage.style.transform = null;
                } else {
                    const transform = progress * 225;
                    ourTeamImage.style.transform = `translateX(-${transform}dvw)`;
                }
            }
        }
    });
}