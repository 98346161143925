import { turnOffLenis } from "../scroll.js";

export function hero() {
    const heroSection = document.querySelector('.hero-section');
    const heroAnimationObject = document.querySelector('#hero-animate');

    const image = heroAnimationObject.querySelector('img');
    const servicesText = document.querySelector('.services-text');
    const header = document.querySelector('header');

    // const heroSectionImages = document.querySelectorAll('.hero-section__item');
    // heroSectionImages.forEach(item=>{
    //     const img = item.querySelector('img');
    // });

    if(window.innerWidth < 768) return;
    
    const tl = gsap.timeline({
        scrollTrigger: {
            scrub: 1,
            pin: true,
            trigger: heroSection,
            start: "top top",
            endTrigger: ".hero-section_wrap",
            end: "bottom bottom",
            onLeave: () => {
                header.style.position = 'absolute';
                heroSection.classList.add('d-none');

                turnOffLenis()
            },
            onEnterBack: () => {
                heroAnimationObject.style.backgroundColor = 'color-mix(in srgb, #262626 90%, #FFFFFF 10%)';
                header.style.position = null;
                heroSection.classList.remove('d-none');
                setTimeout(() => {
                    heroAnimationObject.style.backgroundColor = null;
                }, 500);
            }
        },
    });
    
    tl.to(heroAnimationObject,{
        onUpdate: () => {
            const progress = tl.progress();
            let width = Math.max(progress * 150, 10);
            let height = Math.max(progress * 150, 4);
            if(width == 10) {
                width = 10 + 'rem';
                heroAnimationObject.style.transform = null;
            } else {
                width = width + 'dvw';
                heroAnimationObject.style.transform = 'translate(-50%, -50%)';
            };
            if(height == 4) {
                height = height + 'rem';
            } else {
                height = height + 'dvw';
            };
            if(progress > 0.5) {
                servicesText.style.opacity = progress;
                if(window.innerWidth > 768) {
                    servicesText.style.transform = `translateX(-50%) scale(${progress})`;
                } else {
                    servicesText.style.transform = `scale(${progress})`;
                }
                servicesText.classList.add('active');
            } else {
                servicesText.style.opacity = null;
                servicesText.style.scale = null;    
                servicesText.classList.remove('active');
            }
            image.style.transform = `translateY(${progress * 35}dvh)`;
            heroAnimationObject.style.maxHeight = height;
            heroAnimationObject.style.height = height;
            heroAnimationObject.style.width = width;
        }
    });
}