export function counter() {
    const wrapper = document.querySelector('.counter-section_wrap');
    const counterSection = document.querySelector('.counter-section');

    const textBlock = document.querySelector('.counter-section__text-block');
    const counter = textBlock.querySelector('.__counter');
    
    const tl = gsap.timeline({
        scrollTrigger: {
            scrub: 1,
            pin: true,
            trigger: counterSection,
            start: "-100% bottom",
            endTrigger: wrapper,
            end: "bottom top",
            onEnter: () => {
                textBlock.style.filter = null;
            },
            onEnterBack: () => {
                textBlock.style.filter = null;
            }
        }
    });
    tl.to(textBlock,{
        onUpdate: ()=>{
            const progress = tl.progress();

            let blur = Math.max((1 - progress) * .5, 0);
            if(blur < .15) blur = 0;
            const numbers = `${Math.min((progress * 1.2) * 100.3, 100.3)}`;
            let result = numbers.slice(0, 5);

            counter.innerHTML = `${result}`;

            textBlock.style.opacity = progress;
            textBlock.style.transform = `translate(-50%, -50%) scale(${Math.max((1 - progress) * 5, 1)})`;
            textBlock.style.filter = `blur(${blur}rem)`;
        }
    });
}