import * as scroll from './scroll.js';

import { customCursor } from "./customCursor.js";
import { slider } from './slider.js';

import { hero } from './animations/hero.js';
import { services } from './animations/services.js';
import { counter } from './animations/counter.js';
import { plusFalling } from './animations/plusFalling.js';
import { howWeWork } from './animations/howWeWork.js';
import { clientsTrust } from './animations/clientsTrust.js';
import { letters } from './animations/letters.js';
import { google } from './animations/google.js';
import { cases } from './animations/cases.js';
import { projectsUppear } from './animations/projectsUppear.js';
import { projectsScroll } from './animations/projectsScroll.js';
import { benefits } from './animations/benefits.js';
import { text } from './animations/text.js';

import { preloads } from './preloads.js';

customCursor()
slider()

hero()
services()
counter()
plusFalling()
howWeWork()
clientsTrust()
letters()
google()
cases()
projectsUppear()
projectsScroll()
benefits()
text()

preloads()