// import Lenis from 'lenis'

export const lenis = new Lenis({
    lerp: 0.07, // animation smoothness (between 0 & 1)
    wheelMultiplier: 0.9, // scrolling speed for mouse wheel
    touchMultiplier: 0.9, // scrolling speed for touch events
    smoothWheel: true, // smooth scrolling for while events
    smoothTouch: true, // smooth scrolling for touche events
    orientation: "vertical", // orientation of the scrolling (vertical/horizontal)
    gestureOrientation: "vertical", // orientation of the gestures (vertical/horizontal)
    normalizeWheel: false, // Normalize wheel inputs
    infinite: false, // infinite scroll
    autoResize: true
});

function raf(time) {
    lenis.raf(time);
    requestAnimationFrame(raf);
}

requestAnimationFrame(raf);

export function turnOffLenis(timing) {
    lenis.stop();
    if(timing) {
        setTimeout(() => {
            lenis.start();
        }, timing);
    } else {
        setTimeout(() => {
            lenis.start();
        }, 1000);
    }
}