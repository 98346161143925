import { turnOffLenis } from "../scroll.js";

export function benefits() {
    const wrapper = document.querySelector('.benefits-section_wrap');
    const benefits = document.querySelector('.benefits-section');

    const infoBlock = document.querySelector('.benefits-section__info-block');
    const progressBar = infoBlock.querySelector('.__progress-bar');
    const numbersBlock = document.querySelector('.projects-uppear-section__info-block__numbers-block');
    const numbersMobile = document.querySelector('.__animated_number[data-index="2"]');

    const textBlock = document.querySelector('.__benefits_text');
    const imageBlock = document.querySelector('.__benefits_image');

    const tl = gsap.timeline({
        scrollTrigger: {
            scrub: 1,
            pin: true,
            trigger: benefits,
            start: "bottom bottom",
            endTrigger: wrapper,
            end: "bottom top",
            onLeave: ()=>{
                turnOffLenis(1500);
                numbersBlock.style.height = '250dvh';
                textBlock.style.top = '0';
                infoBlock.classList.remove('active');
                if(window.innerWidth < 768) {
                    numbersMobile.style.height = '150vh';
                }
            },
            onEnterBack: ()=>{
                turnOffLenis(1500);
                numbersBlock.style.height = null;
                textBlock.style.top = null;
                infoBlock.classList.add('active');
                if(window.innerWidth < 768) {
                    numbersMobile.style.height = 'calc(100vw - 2rem)';
                }
            }
        }
    });
    let index = 1;
    tl.to(infoBlock,{
        onUpdate: ()=>{
            const progress = tl.progress();
            
            if(progress < .001) {
                infoBlock.classList.remove('active');
                textBlock.classList.remove('active');
                imageBlock.classList.remove('active');
            } else {
                if(!infoBlock.classList.contains('active'))infoBlock.classList.add('active');
                if(!textBlock.classList.contains('active'))textBlock.classList.add('active');
                if(!imageBlock.classList.contains('active'))imageBlock.classList.add('active');
            }

            if(window.innerWidth > 768) {
                progressBar.style.height = `${progress * 100}%`;
            } else {
                progressBar.style.width = `${progress * 100}%`;
            }

            let newIndex = 1;
            if(progress < .35) {
                newIndex = 1;
            } else if(progress > .35 && progress < .6) {
                newIndex = 2;
            } else if(progress > .6 && progress < .9) {
                newIndex = 3;
            } else if(progress > .9 && progress <= 1) {
                newIndex = 4;
            }
            if(newIndex !== index) {
                index = newIndex;
                setNewContent(index);
            }
        }
    });
}

function setNewContent(number) {
    const textBlock = document.querySelector('.__benefits_text');
    const imageBlock = document.querySelector('.__benefits_image');
    const progressItems = document.querySelectorAll('.benefits-section__info-block__progress-block__item');

    let text = '';
    let image = '';

    switch (number) {
        case 1:
            text = `<span>Единая стратегия</span>Все действия направлены на достижение общих целей, что обеспечивает согласованность и эффективность работы.`;
            image = `<img src="./src/assets/images/benefits/benefits-1.png" alt="benefits image">`;
            break;
        case 2:
            text = `<span>Снижение затрат</span>Комплексный подход позволяет оптимизировать расходы, исключив дублирование работ и сократив затраты на координацию различных специалистов.`;
            image = `<img src="./src/assets/images/benefits/benefits-2.png" alt="benefits image">`;
            break;
        case 3:
            text = `<span>Решение под ваш бизнес</span>Все решения разрабатываются и внедряются в соответствии с общей стратегией, что обеспечивает их полную совместимость и функциональность.`;
            image = `<img src="./src/assets/images/benefits/benefits-3.png" alt="benefits image">`;
            break;
        case 4:
            text = `<span>Личный менеджер 24/7</span>Благодаря единому контактному лицу, вы всегда в курсе всех этапов работ. Это упрощает взаимодействие и ускоряет процесс принятия решений.`;
            image = `<img src="./src/assets/images/benefits/benefits-4.png" alt="benefits image">`;
            break;
    }

    progressItems.forEach(item=>{
        const index = Number(item.dataset.number);
        if(index <= number) {
            item.classList.add('active');
        } else {
            item.classList.remove('active');
        }
    });

    textBlock.innerHTML = text;
    imageBlock.innerHTML = image;
    textBlock.classList.add('animated');
    imageBlock.classList.add('animated');
    setTimeout(() => {
        textBlock.classList.remove('animated');
        imageBlock.classList.remove('animated');
    }, 250);
}