import { turnOffLenis } from "../scroll.js";

export function cases() {
    const wrapper = document.querySelector('.cases-section_wrap');
    const cases = document.querySelector('.cases-section');

    const casesBlock = document.querySelector('.cases-section__cases');
    const imageFixed = document.querySelector('.letters-section__our-team');
    const googleText = document.querySelector('.google-section__text');

    const tl = gsap.timeline({
        scrollTrigger: {
            scrub: 1,
            pin: true,
            trigger: cases,
            start: "bottom bottom",
            endTrigger: wrapper,
            end: "bottom top",
            onLeave: ()=>{
                turnOffLenis();
                imageFixed.classList.remove('active');
                googleText.classList.remove('active');
            },
            onEnterBack: ()=>{
                turnOffLenis(500);
                imageFixed.classList.add('active');
                googleText.classList.add('active');
            }
        }
    });
    tl.to(casesBlock,{
        onUpdate: ()=>{
            const progress = tl.progress();
            
            if(window.innerWidth > 768) {
                casesBlock.style.right = `-${(1 - progress) * 150}dvw`;
                casesBlock.style.bottom = `-${(1 - progress) * 150}dvh`;
            } else {
                casesBlock.style.left = `${(1 - progress) * 150}vw`;
                casesBlock.style.top = `${(1 - progress) * 150}vh`;
            }
        }
    });
}