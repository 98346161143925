import { turnOffLenis } from "../scroll.js";

export function clientsTrust() {
    const wrapper = document.querySelector('.clients-trust-section_wrap');
    const clientsTrust = document.querySelector('.clients-trust-section');

    const text = clientsTrust.querySelector('.clients-trust-section__text')

    const tl = gsap.timeline({
        scrollTrigger: {
            scrub: 1,
            pin: true,
            trigger: clientsTrust,
            start: "bottom bottom",
            endTrigger: wrapper,
            end: "bottom top",
            onLeave: ()=>{
                turnOffLenis(500);
                clientsTrust.classList.remove('active');
            },
            onEnterBack: ()=>{
                turnOffLenis(500);
                clientsTrust.classList.add('active');
            }
        }
    });
    tl.to(text,{
        onUpdate: ()=>{
            const progress = tl.progress();
            const translateY = Math.max(progress * 750, 50);

            text.style.transform = `translate(-50%, -${translateY}%)`;
        }
    });
}