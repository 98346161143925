import { turnOffLenis } from "../scroll.js";

export function howWeWork() {
    const wrapper = document.querySelector('.how-we-work-section_wrap');
    const hwwSection = document.querySelector('.how-we-work-section');

    const animateElem = document.querySelector('.__hww-animate');
    const clientsTrust = document.querySelector('.clients-trust-section');

    const tl = gsap.timeline({
        scrollTrigger: {
            scrub: 1,
            pin: true,
            trigger: hwwSection,
            start: "bottom bottom",
            endTrigger: wrapper,
            end: "bottom top",
            onLeave: ()=>{
                turnOffLenis(500);
                clientsTrust.classList.add('active');
            },
            onEnterBack: ()=>{
                turnOffLenis(500);
                clientsTrust.classList.remove('active');
            }
        }
    });
    tl.to(animateElem,{
        onUpdate: ()=>{
            const progress = tl.progress();
            
            if(window.innerWidth > 768) {
                animateElem.style.transform = `translateY(${progress * 150}%) rotate(${progress * 75}deg) scale(${Math.max(progress * 250, 1)}`;
            } else {
                animateElem.style.transform = `translateY(${progress * 150}%) rotate(${progress * 75}deg) scale(${Math.max(progress * 500, 1)}`;
            }
        }
    })
}