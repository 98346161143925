export function customCursor() {
    const customCursorElem = document.querySelector('.custom-cursor');
    if(window.innerWidth > 768) {
        document.addEventListener('pointermove',(event)=>{
            const cursorBlock = event.target.closest('.__custom_cursor__block')
            customCursorElem.style.top = event.y + 'px';
            customCursorElem.style.left = event.x + 'px';
            if(!cursorBlock) {
                customCursorElem.classList.remove('active');
                return;
            }
            const newText = cursorBlock.dataset.text;
            customCursorElem.innerText = newText;
            customCursorElem.classList.add('active');
            if(cursorBlock.classList.contains('__custom_cursor__block_inverse')) {
                customCursorElem.classList.add('inverse');
            } else {
                customCursorElem.classList.remove('inverse');
            }
        }, {passive: true});
    }
}