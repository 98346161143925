export function slider() {
    const sliderSections = document.querySelectorAll('.__custom_slider__section');

    sliderSections.forEach(section=>{
        const slides = section.querySelectorAll('.__custom_slider__slide');
        const allSlides = section.querySelector('.__custom_slider__all_slides');

        allSlides.innerText = '0' + `${slides.length}`;

        slides.forEach((slide, index)=>{
            if(index == 0) {
                setSlideActive(section, slide);
            }

            slide.addEventListener('click',()=>{
                slides.forEach(unactive=>{
                    setSlideUnactive(unactive);
                });
                setSlideActive(section, slide)
            });
        });
    });


    const casesSlider = new Swiper('.__cases_slider',{
        slidesPerView: 1.05,
        spaceBetween: getRem() * 1,
        breakpoints: {
            769: {
                slidesPerView: 2.7,
                spaceBetween: getRem() * 1.25,
            }
        },
        navigation: {
            prevEl: '.__cases_slider__btn_prev',
            nextEl: '.__cases_slider__btn_next'
        },
        mousewheel: {
            forceToAxis: true
        }
    });

    const hwwSlider = new Swiper('.__hww_slider',{
        slidesPerView: 1.05,
        spaceBetween: getRem() * .5,
        navigation: {
            prevEl: '.__hww_slider__btn_prev',
            nextEl: '.__hww_slider__btn_next'
        },
        mousewheel: {
            forceToAxis: true
        }
    });

    const hwwSliderSection = document.querySelector('.__custom_slider__section.how-we-work-section')
    const currentSlide = hwwSliderSection.querySelector('.__custom_slider__current_slide');
    hwwSlider.on('activeIndexChange', (event)=> {
        const slides = event.slides;
        setTimeout(() => {
            slides.forEach((slide,index)=>{
                if(slide.classList.contains('swiper-slide-active')) currentSlide.innerText = '0' + (index + 1);
            });
        }, 100);
    });
}

export function getRem() {
    var element = document.querySelector("html");
    var fontSize = window.getComputedStyle(element).fontSize;
    var numericFontSize = parseInt(fontSize, 10);
    return fontSize.slice(0, -2);
}


function setSlideActive(section, slide) {
    const currentSlide = section.querySelector('.__custom_slider__current_slide');
    currentSlide.innerText = '0' + `${slide.dataset.number}`;
    slide.classList.add('active');
    slide.style.maxWidth = (slide.scrollWidth * 2) + 'px';
}

function setSlideUnactive(slide) {
    slide.classList.remove('active');
    slide.style.maxWidth = null;
}